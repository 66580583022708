<template>
  <ams-table
    :ams_api_path="ams_api_path"
    :ams_type="ams_type"
    :ams_fields="ams_fields"
    :describe_belongs_to="describe_belongs_to"
  />
</template>

<script>
import AmsTable from './AmsTable.vue'

export default {
  components: {
    AmsTable
  },

  data() {
    return {
      ams_type: 'item-image-associations',
      ams_api_path: 'api/admin/v1',
      ams_fields: [
        { 
          key: 'attributes.product-item-id',
          label: 'Product Item ID',
          sortable: true,
          filterable: true,
          class: 'text-center',
          sortDirection: 'asc' 
        },
        { 
          key: 'attributes.collection-image-id',
          label: 'Collection Image ID',
          sortable: true,
          filterable: true,
          class: 'text-center',
          sortDirection: 'asc' 
        },
        { key: 'actions', label: 'Actions' }
      ],
      describe_belongs_to: [
        {
          prepend: '',
          type: 'product-models',
          description_attr: 'name',
          append: ' '
        },
        {
          prepend: '',
          type: 'collections',
          description_attr: 'name',
          append: ' '
        },
        {
          prepend: ' ',
          type: 'brands',
          description_attr: 'name',
          append: ' '
        },
      ]
    }
  }
}
</script>